import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";

const BlogItem = ({ post }) => (
  <Col md={12} sm={6} xs={12}>
    <Card className="card mb-0 w-100">
      <Card.Img variant="top" src={post.jetpack_featured_media_url} />
    </Card>
    <div>
      <div>
        <p className="fs-3 mt-2 text-gray fw-bold">{post.title.rendered}</p>
        <div
          className="text-gray"
          dangerouslySetInnerHTML={{
            __html: post.excerpt.rendered
              .replace(/<p>/g, "")
              .replace(/<\/p>/g, ""),
          }}
        ></div>
        <Link
          to={`/Blog/${post.slug}`}
          className="text-decoration-none text-blue fw-bold"
        >
          Continuar Leyendo
        </Link>
      </div>
    </div>
  </Col>
);

const Aside = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cachedData = localStorage.getItem("cachedData");
    if (cachedData) {
      const cachedDataArray = JSON.parse(cachedData);
      const firstThreeItems = cachedDataArray.slice(0, 3);
      if (firstThreeItems.length > 0) {
        setData(firstThreeItems);
      } else {
        setError("No hay blogs en caché para mostrar.");
      }
    } else {
      setError("No hay blogs en caché para mostrar.");
    }
  
  }, []);

  return (
    <Container className="mb-3">
      <Row>
        {error ? (
          <Col>
            <p>{error}</p>
          </Col>
        ) : (
          data.map((post) => <BlogItem key={post.id} post={post} />)
        )}
      </Row>
    </Container>
  );
};

export default Aside;
