import React, { useState } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";

import Swal from "sweetalert2";

const FormContact = () => {
  const [selectedCity, setSelectedCity] = useState("Ciudad");
  const [selectedService, setSelectedService] = useState(
    "Selecciona un servicio"
  );
  const services = ["Red de incendios", "Red de gas", "Detección de humo"];

  const [citiesDropdownVisible, setCitiesDropdownVisible] = useState(false);
  const [servicesDropdownVisible, setServicesDropdownVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const cities = [
    " Arauca ",
    " Armenia ",
    " Barranquilla ",
    " Bogotá ",
    " Bucaramanga ",
    " Cali ",
    " Cartagena ",
    " Cúcuta ",
    " Florencia ",
    " Ibagué ",
    " Leticia ",
    " Manizales ",
    " Medellí ",
    " Mitú ",
    " Mocoa ",
    " Montería ",
    " Neiva ",
    " Pasto ",
    " Pereira ",
    " Popayán ",
    " Puerto Carreño ",
    " Puerto Inírida ",
    " Quibdó ",
    " Riohacha ",
    " San Andrés ",
    " San José del Guaviare ",
    " Santa Marta ",
    " Sincelejo ",
    " Tunja ",
    " Valledupar ",
    " Villavicencio ",
    " Yopal ",
  ];
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    formData.append("Ciudad", selectedCity);
    formData.append("Servicio", selectedService);
    submitForm(form, formData);
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setCitiesDropdownVisible(false);
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setServicesDropdownVisible(false);
  };

  const submitForm = async (form, formData) => {
    setLoader(true);
    const submitButton = document.getElementById("submit-button");
    submitButton.disabled = true;
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwSa87ulbz3GO7TV0czoWo0dTL_zNv9ON4wJGNHFVd5G4i5z-VtRX5MyFGdaBSs-e6pyg/exec",
        { method: "POST", body: formData }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Hemos recibido tus datos con éxito",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 1500,
          customClass: {
            popup: "my-swal-popup",
          },
        });
        setLoader(false);
        form.reset();
        submitButton.disabled = false; // Limpia los campos del formulario
      } else {
        throw new Error("Error en la respuesta del servidor");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Lo sentimos, hemos presentado un error,intentelo más tarde",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 1500,
        customClass: {
          popup: "my-swal-popup",
        },
      });
    } finally {
      submitButton.disabled = false;
    }
  };

  const validation = (event) => {
    const id = event.target;
    id.setCustomValidity("");
    if (!id.checkValidity()) {
      id.setCustomValidity("Este campo es obligatorio");
      id.classList.add("is-invalid");
    } else {
      id.classList.remove("is-invalid");
    }
  };

  const validateInput = () => {
    var input = document.getElementById("Telefono");
    input.setCustomValidity("");
    if (!input.checkValidity()) {
      input.setCustomValidity("El número debe tener 10 dígitos");
      input.classList.add("is-invalid");
      return false;
    }
    input.classList.remove("is-invalid");
    return true;
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8}>
          <Form
            className="d-flex align-items-center justify-content-center"
            method="POST"
            onSubmit={handleFormSubmit}
          >
            <Form.Group
              className="mb-3 contact-form p-5 align-items-center justify-content-center"
              controlId="formContact"
            >
              {loader && (
                <div className="d-flex flex-column  align-items-center">
                  <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
              <h3 className="fs-5 fw-bold text-white  text-center">
                Asesoría gratuita
              </h3>

              <Form.Control
                type="text"
                placeholder="Nombre"
                name="Nombre"
                required
                onBlur={validation}
                className="my-3  form-input"
              />
              <Form.Control
                type="text"
                placeholder="Apellido"
                name="Apellido"
                required
                onBlur={validation}
                className="my-3  form-input"
              />
              <Form.Control
                type="email"
                placeholder="Correo"
                name="Correo"
                required
                onBlur={validation}
                className="my-3  form-input"
              />
              <Form.Control
                type="text"
                placeholder="Empresa"
                name="Empresa"
                className="my-3  form-input"
              />
              <div className="form-control form-input">
                <div
                  className="select-selected"
                  onClick={() =>
                    setCitiesDropdownVisible(!citiesDropdownVisible)
                  }
                >
                  {selectedCity}
                </div>
                <div
                  className={`select-items ${
                    citiesDropdownVisible ? "show" : ""
                  }`}
                >
                  {cities.map((city, index) => (
                    <div
                      className="item"
                      key={index}
                      onClick={() => handleCitySelect(city)}
                    >
                      {city}
                    </div>
                  ))}
                </div>
              </div>
              <Form.Group controlId="Telefono">
                <Form.Control
                  type="tel"
                  placeholder="Número de telefónico "
                  name="Telefono"
                  pattern="[1-9]{1}[0-9]{9}"
                  required
                  onBlur={validateInput}
                  className="my-3 form-input"
                />
              </Form.Group>
              <div className="form-control form-input">
                <div
                  className="select-selected"
                  onClick={() =>
                    setServicesDropdownVisible(!servicesDropdownVisible)
                  }
                >
                  {selectedService}
                </div>
                <div
                  className={`select-items ${
                    servicesDropdownVisible ? "show" : ""
                  }`}
                >
                  {services.map((service, index) => (
                    <div
                      className="item"
                      key={index}
                      onClick={() => handleServiceSelect(service)}
                    >
                      {service}
                    </div>
                  ))}
                </div>
              </div>
              <Button
                type="submit"
                id="submit-button"
                className="text-center mt-3 btn-contact mx-auto center-content"
              >
                Enviar
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default FormContact;
