import React from "react";
import { Row, Col } from "react-bootstrap";
import Services from "./Services";
import Gallery from "./Gallery.js";
import CardComponent from "./Card";
import llama from "../assets/Servicios/Incendios/Llama.gif";

import proyecto1 from "../assets/Servicios/Incendios/Galeria/Proyecto1.png";
import proyecto2 from "../assets/Servicios/Incendios/Galeria/Proyecto2.png";
import proyecto3 from "../assets/Servicios/Incendios/Galeria/Proyecto3.png";
import proyecto4 from "../assets/Servicios/Incendios/Galeria/Proyecto4.png";
import proyecto5 from "../assets/Servicios/Incendios/Galeria/Proyecto5.png";

import Header from "./Header";
import Footer from "./Footer";

function Incendios() {
  const galleryImages = [proyecto1, proyecto2, proyecto3, proyecto4, proyecto5];
  const services = {
    title: "Red Blaze: ",
    subtitle: "Protección de incendios Inteligentes y confiables",
    description:
      "Nuestra red de incendios, con sistemas de extinción de fuego a base de agua, ofrece detección temprana y alerta rápida desde un panel central de alarma. Suprimimos y combatimos el fuego de manera efectiva en hogares, empresas, locales comerciales, restaurantes, centros comerciales, fábricas y bodegas. Obtén tranquilidad y confianza con Red Blaze, protegiendo tus propiedades y cumpliendo normativas de seguridad.",
  };
  return (
    <div>
      <Header />
      <section>
        <h2 className="display-1 text-red text-center mb-5 text-red">
          Red de <span className="fw-bold">incendios</span>
        </h2>
        <Row className="g-0">
          <Col md={8}>
            <Gallery galleryImages={galleryImages} />
          </Col>
          <Col md={4}>
            <CardComponent
              title={services.title}
              subtitle={services.subtitle}
              description={services.description}
            />
          </Col>
        </Row>
        <Services service={llama} />
      </section>
      <Footer />
    </div>
  );
}

export default Incendios;
