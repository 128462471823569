import React, { useEffect, useState } from "react";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel";
import "./styles/Gallery.css";

const Gallery = (galleryImages) => {
  const [carouselClicked, setCarouselClicked] = useState(false);

  useEffect(() => {
    // Inicializar el Carousel después de que el componente se haya montado
    const owlCarousel = window.jQuery(".owl-carousel");
    owlCarousel.owlCarousel({
      loop: true,
      center: true,
      autoplay: false,
      smartSpeed: 5000,
      fluidSpeed: 5000,
      responsiveClass: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },

      nav: true,
      navText: [
        "<i class='fas fa-arrow-left'></i>",
        "<i class='fas fa-arrow-right'></i>",
      ],
    });

    // Activar autoplay solo si el carousel ha sido clickeado
    if (carouselClicked) {
      owlCarousel.trigger("play.owl.autoplay", [5000]);
    }

    // Desactivar autoplay cuando el componente se desmonte
    return () => {
      owlCarousel.trigger("stop.owl.autoplay");
    };
  }, [carouselClicked]);

  const handleCarouselClick = () => {
    setCarouselClicked(true);
  };

  return (
    <div className="owl-carousel owl-theme p-0" onClick={handleCarouselClick}>
      {galleryImages.galleryImages.map((image, index) => (
        <div
          className="item d-flex justify-content-center align-items-center p-0"
          key={index}
        >
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            className="img-fluid w-100 p-0 m-0"
            style={{ height: "425px" }}
          />
        </div>
      ))}
    </div>
  );
};

export default Gallery;