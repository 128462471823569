//App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  
} from "react-router-dom";
import Us from "./components/Us";
import Banner from "./components/Banner";
import Incendios from "./components/Incendios";
import Gas from "./components/Gas";
import Contact from "./components/Contact";
import Humo from "./components/Humo";
import Blog from "./components/Blog";
import ContentBlog from "./components/ContentBlog";
import Whatsapp from "./components/Whatsapp";
import Pagina404 from "./components/Pagina404";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/nosotros" element={<Us />} />
          <Route exact path="/" element={<Banner />} />
          <Route exact path="/Incendios" element={<Incendios />} />
          <Route exact path="/Humo" element={<Humo />} />
          <Route exact path="/Gas" element={<Gas />} />
          <Route exact path="/Contacto" element={<Contact />} />
          <Route exact path="/Blog" element={<Blog />} />
          <Route
            exact
            path="/Blog/:postTile/"
            element={<ContentBlog />}
          />
          <Route path="*" element={<Pagina404 />} />
          <Route path="/Blog/*" element={<Pagina404 />} />
        </Routes>

        <Whatsapp />
      </Router>
    </div>
  );
}

export default App;
