import React from "react";
import { Row, Col } from "react-bootstrap";
import Services from "./Services";
import Gallery from "./Gallery.js";
import CardComponent from "./Card";
import Reloj from "../assets/Servicios/Gas/Reloj.gif";

import proyecto1 from "../assets/Servicios/Gas/Galeria/proyecto1.png";
import proyecto2 from "../assets/Servicios/Gas/Galeria/proyecto2.png";
import proyecto3 from "../assets/Servicios/Gas/Galeria/proyecto3.png";
import proyecto4 from "../assets/Servicios/Gas/Galeria/proyecto4.png";
import proyecto5 from "../assets/Servicios/Gas/Galeria/proyecto5.png";
import Header from "./Header";
import Footer from "./Footer";
function Gas() {
  const galleryImages = [proyecto1, proyecto2, proyecto3, proyecto4, proyecto5];
  const services = {
    title: "Suministro de Gases: ",
    subtitle: "Eficiencia y Conveniencia para Varios Sectores",
    description:
      "Nuestro sistema de suministro de gases ofrece abastecimiento constante en proyectos domésticos, industriales y comerciales. A través de una red de gasoductos, distribuimos gas natural, propano y butano, optimizando la presión para reducir costos y pérdidas. Garantizamos un suministro eficiente y controlado, brindando comodidad y satisfacción en tus instalaciones.",
  };
  return (
    <div>
      <Header />
      <section className="services">
        <h2 className="display-1 text-red text-center mb-5 text-red">
          Red de <span className="fw-bold">Gas</span>
        </h2>
        <Row className="m-0 p-0 g-0">
          <Col md={8}>
            <Gallery galleryImages={galleryImages} />
          </Col>
          <Col md={4}>
            <CardComponent
              title={services.title}
              subtitle={services.subtitle}
              description={services.description}
            />
          </Col>
        </Row>
        <Services service={Reloj} />
      </section>
      <Footer />
    </div>
  );
}

export default Gas;
