import React from "react";
import { Card, Container } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";

import Mision from "../assets/Nosotros/Mision.png";
import Vision from "../assets/Nosotros/Vision.png";
import Valores from "../assets/Nosotros/Valores.png";
import "./styles/Us.css";

function Us() {
  return (
    <div>
      <Header />
      <section className="us mt-5">
        <Container className="d-flex justify-content-center mb-4">
          <div className="d-flex flex-column align-items-center">
            <Card className="card mb-4">
              <Card.Img
                variant="top"
                src={Mision}
                className="card-image img-fluid "
                style={{
                  minHeight: "100px",
                }}
              />
              <Card.Body className="card-us">
                <Card.Title className="display-3 text-white">
                  Nuestra <br />
                  <span className="display-1 text-white fw-bold">Misión</span>
                </Card.Title>

                <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-white  invisible">
                  <p className="lead">
                    Proteger vidas y salvaguardar propiedades mediante la
                    implementación de sistemas integrales de seguridad contra
                    incendios y detección de humo, además de brindar servicios
                    de instalación de redes de gas en diversos sectores. Nuestro
                    compromiso es brindar soluciones confiables y eficientes
                    cumpliendo con los más altos estándares de calidad y
                    seguridad para garantizar la tranquilidad y el bienestar de
                    nuestros clientes.
                  </p>
                </Card.ImgOverlay>
              </Card.Body>
            </Card>
            {/*Vision */}
            <Card className="card mb-4">
              <Card.Img
                variant="top"
                src={Vision}
                className="card-image img-fluid "
                style={{
                  minHeight: "100px",
                }}
              />
              <Card.Body className="card-us">
                <Card.Title className="display-3 text-white">
                  Nuestra <br />
                  <span className="display-1 text-white fw-bold">Visión</span>
                </Card.Title>

                <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-white invisible">
                  <p className="lead">
                    Ser una empresa líder en la industria de la protección
                    contra incendios, detección de humo y redes de gas
                    reconocida por su esencia en el diseño, instalación y
                    mantenimiento de sistemas innovadores y confiables. Nuestro
                    objetivo es expandir el alcance de la empresa a nivel
                    nacional e internacional por medio de alianzas estratégicas
                    y colaboración con nuestros clientes para convertirnos en su
                    socio en cuanto a seguridad y protección de sus
                    instalaciones.
                  </p>
                </Card.ImgOverlay>
              </Card.Body>
            </Card>
            {/*Valores */}
            <Card className="card mb-4">
              <Card.Img
                variant="top"
                src={Valores}
                className="card-image img-fluid "
                style={{
                  minHeight: "100px",
                }}
              />
              <Card.Body className="card-us w-100">
                <Card.Title className="display-3 text-white">
                  Nuestros <br />
                  <span className="display-1 text-white fw-bold">Valores</span>
                </Card.Title>
                <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center text-white  invisible">
                  <ul className="custom-list no-gutters text-white ">
                    <li> Calidad</li>
                    <li>Responsabilidad</li>
                    <li>Honestidad</li>
                    <li>Compromiso</li>
                    <li>Cumplimiento</li>
                  </ul>
                </Card.ImgOverlay>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
}
export default Us;
