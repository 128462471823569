import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/Logos/logoRedBlaze.png";
import "./styles/Header.css";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const closeMenu = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsMenuOpen(false);
        setIsSubMenuOpen(false); // Close submenu when clicking outside
      }
    };

    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  const handleMenuOptionClick = (url) => {
    setIsMenuOpen(!isMenuOpen);
    setIsSubMenuOpen(false); // Close submenu when clicking a menu option
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSubMenuOpen(false); // Close submenu when toggling the menu
  };


  return (
    <Container className="d-flex justify-content-center">
      <Row className="align-items-center mx-2 mt-3 justify-content-center">
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="d-flex align-items-center justify-content-center"
        >
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="Logo Natural Duos'x"
              height="200"
              width="200"
              className="img-fluid"
            />
          </Navbar.Brand>
        </Col>
        <Col
          xs={6}
          sm={6}
          md={8}
          lg={9}
          xl={10}
          className="d-flex align-items-center justify-content-center"
        >
          <div ref={menuRef}>
            <Navbar
              id="navbar"
              expand="lg"
              className={`navbar ${isMenuOpen ? "show-navbar" : ""}`}
            >
              <Navbar.Toggle
                aria-controls="navbar-nav"
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                  setIsSubMenuOpen(false); // Close submenu when toggling the menu
                }}
                className="navbar-toggler"
              />
              <Navbar.Collapse
                id="navbar-nav"
                in={isMenuOpen}
                onToggle={handleMenuToggle}
              >
                <Nav className="ml-auto">
                  <Nav.Link
                    as={Link}
                    to="/"
                    onClick={() => handleMenuOptionClick("/")}
                    className="fs-4 text-color  p-md-3 text-center"
                    active
                  >
                    Inicio
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/nosotros"
                    onClick={() => handleMenuOptionClick("/nosotros")}
                    className="fs-4 text-color  p-md-3 text-center"
                  >
                    Nosotros
                  </Nav.Link>

                  <NavDropdown
                    title="Servicios"
                    id="basic-nav-dropdown"
                    className="fs-4 text-color   p-md-2 text-center"
                  >
                    <NavDropdown.Item href="/Incendios">
                      Red de incendios
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/Gas">Red de gas</NavDropdown.Item>
                    <NavDropdown.Item href="/Humo">
                      Detección de humo
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link
                    as={Link}
                    to="/Blog"
                    onClick={() => handleMenuOptionClick("/Blog")}
                    className="fs-4 text-color  p-md-3 text-center"
                  >
                    Blog
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/contacto"
                    onClick={() => handleMenuOptionClick("/Contacto")}
                    className="fs-4 text-color  p-md-3 text-center"
                  >
                    Contáctanos
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Header;
