import React, { useEffect } from "react";
import Seguridad from "../assets/Home/Servicios/Seguridad.png";
import Confiabilidad from "../assets/Home/Servicios/Confiabilidad.png";
import Eficacia from "../assets/Home/Servicios/Eficacia.png";
import Innovacion from "../assets/Home/Servicios/Innovacion.png";
import Monitoreo from "../assets/Home/Servicios/Monitoreo.png";
import Prevencion from "../assets/Home/Servicios/Prevencion.png";
import Proteccion from "../assets/Home/Servicios/Proteccion.png";
import "./styles/Slider.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel";

const sliderImages = [
  Proteccion,
  Seguridad,
  Confiabilidad,
  Eficacia,
  Innovacion,
  Monitoreo,
  Prevencion,
];
const Slider = () => {
  useEffect(() => {
    // Inicializar el Carousel después de que el componente se haya montado
    const owlCarousel = window.jQuery(".owl-carousel");
    owlCarousel.owlCarousel({
      loop: true,
      center: true,
      stagePadding: 50,
      dots: false,
      smartSpeed: 10000,
      fluidSpeed:10000,
      autoplay: true,
      margin: 50,
      responsiveClass: true,
      responsive: {
        0: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
  }, []);

  return (
    <div className="owl-carousel owl-theme my-3">
      {sliderImages.map((image, index) => (
        <div className="item p-0" key={index}>
          <img
            src={image}
            alt={`Slide ${index + 1}`}
            className="img-fluid w-50"
          />
        </div>
      ))}
    </div>
  );
};

export default Slider;
