import React from "react";
import { Card, Button } from "react-bootstrap";
import Slider from "./Slider";
import "./styles/Banner.css";
import Header from "./Header";
import Footer from "./Footer";
import bannerImage from "../assets/Home/Banner.png";
import bannerImage2 from "../assets/Home/Banner2.png";

const Banner = () => {
  const contactLink =
    "https://api.whatsapp.com/send/?phone=573228279292&text=%C2%A1Hola+Red+Blaze+🔥+Vi+sus+servicios+en+la+p%C3%A1gina+web+y+me+gustar%C3%ADa+recibir+una+asesor%C3%ADa+personalizada&type=phone_number&app_absent=0";

  return (
    <div>
      <Header />

      <section className="mb-3">
        <Card className="bg-dark text-white image-banner w-100">
          <Card.Img
            src={bannerImage}
            alt="Banner "
            className="img-reponsive h-100"
            style={{
              minHeight: "200px",
            }}
          />
          <Card.ImgOverlay className="d-flex flex-column justify-content-start align-items-start   mx-5-left principal-container">
            <h1 className="text-center display-1 mb-0">SISTEMAS</h1>
            <h2 className="text-center display-1 ">EFICACES</h2>
            <p className="lead">
              <span>contra incendios</span> a un clic
            </p>
            <a
              className="link-underline link-underline-opacity-0"
              target="_blank"
              href={contactLink}
              rel="noopener noreferrer"
            >
              <Button size="lg" className="btn-contact">
                Contactanos
              </Button>
            </a>
          </Card.ImgOverlay>
        </Card>
        <Slider />
        <Card className="bg-dark text-white image-banner w-100">
          <Card.Img
            src={bannerImage2}
            alt="Banner"
            className="img-reponsive h-100"
            style={{
              minHeight: "200px",
            }}
          />
          <Card.ImgOverlay className="d-flex flex-column justify-content-end align-items-end py-5 mx-5 container-text">
            <h1 className="text-center display-1 ">PROTEGE</h1>
            <h2 className="">tu empresa con</h2>
            <h2 className="text-center display-1 ">NOSOTROS</h2>
          </Card.ImgOverlay>
        </Card>
      </section>
      <Footer />
    </div>
  );
};

export default Banner;