import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import casa from "../assets/Footer/casa.png";
import contacto from "../assets/Footer/contacto.png";
import direccion from "../assets/Footer/ubicacion.png";
import mail from "../assets/Footer/mail.png";
import Facebook from "../assets/Footer/Facebook.png";
import Instagram from "../assets/Footer/Instagram.png";
import logo from "../assets/Logos/logoRedBlazeFooter.png";
import Pdf1 from "../assets/Footer/Archivos/PoliticadeCalidad.pdf";
import Pdf2 from "../assets/Footer/Archivos/Política de Tratamiento de Datos Personales.pdf";

import "./styles/Footer.css";

const footerLinks = [
  {
    icon: casa,
    title: "Sede",
    text: "Bogotá - Colombia",
    href: "#",
    width: 50,
  },
  {
    icon: contacto,
    title: "Contacto",
    text: "322 827 9292",
    href: "https://api.whatsapp.com/send/?phone=573228279292&text=%C2%A1Hola+Red+Blaze+🔥+Vi+sus+servicios+en+la+p%C3%A1gina+web+y+me+gustar%C3%ADa+recibir+una+asesor%C3%ADa+personalizada&type=phone_number&app_absent=0",
    width: 25,
  },
  {
    icon: direccion,
    title: "Dirección",
    text: "Cra 21 #164 -39",
    href: "https://goo.gl/maps/81y4mFxsUosrnB899",
    width: 25,
  },
  {
    icon: mail,
    title: "Correo",
    text: "redblazeproyectos@gmail.com",
    href: "mailto:redblazeproyectos@gmail.com",
    width: 25,
  },
];

const socialLinks = [
  {
    icon: Facebook,
    href: "https://www.facebook.com/people/Red-Blaze/100093894826120/https://www.facebook.com/people/Red-Blaze/100093894826120/",
  },
  {
    icon: Instagram,
    href: "https://www.instagram.com/red_blaze_projects/?igshid=MmIzYWVlNDQ5Yg%3D%3D",
  },
];

const Footer = () => (
  <footer className="pt-5">
    <Container fluid>
      <Row className="flex-wrap">
        <Col md={2} className="d-flex flex-column align-items-center">
          <img
            src={logo}
            className="img-fluid align-middle"
            width={150}
            alt="Logo"
          />
        </Col>
        {footerLinks.map((link, index) => (
          <Col md={2} key={index} className="d-flex align-items-center">
            <img
              src={link.icon}
              className="img-fluid align-middle mb-3 mx-2"
              width={link.width}
              alt={`Footer ${index}`}
            />
            <div className="d-inline-block align-items-start">
              <span className="text-white fw-bold">{link.title}</span>
              <a
                className="link-underline link-underline-opacity-0"
                target="_blank"
                href={link.href}
                rel="noopener noreferrer"
              >
                <p className="text-white fw-light">{link.text}</p>
              </a>
            </div>
          </Col>
        ))}
        <Col
          md={2}
          className="d-flex align-items-center justify-content-center"
        >
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={link.icon}
                width={25}
                height={25}
                alt={`Social ${index}`}
                className="align-items-center mx-2"
              />
            </a>
          ))}
        </Col>
      </Row>
    </Container>
    <div className="text-white text-center py-4">
      <p>
        {" "}
        RedBlaze ® 2023, <span>  </span>
        <a href={Pdf1} target="_blank" rel="noreferrer" className=" text-white footer-pdf">
          {"    "}
           Todos los derechos reservados
        </a>
        <span> – </span>
        <a href={Pdf2} target="_blank" rel="noreferrer" className=" text-white footer-pdf">
          {" "}
          Términos y condiciones
        </a>
      </p>
    </div>
  </footer>
);

export default Footer;
