import React from "react";
import { Row, Col } from "react-bootstrap";
import Services from "./Services";
import Gallery from "./Gallery.js";
import CardComponent from "./Card";
import HumoImage from "../assets/Servicios/Humo/Humo.gif";

import proyecto1 from "../assets/Servicios/Humo/Galeria/proyecto1.png";
import proyecto2 from "../assets/Servicios/Humo/Galeria/proyecto2.png";
import proyecto3 from "../assets/Servicios/Humo/Galeria/proyecto3.png";
import proyecto4 from "../assets/Servicios/Humo/Galeria/proyecto4.png";
import proyecto5 from "../assets/Servicios/Humo/Galeria/proyecto5.png";
import Header from "./Header";
import Footer from "./Footer";
function Humo() {
  const galleryImages = [proyecto1, proyecto2, proyecto3, proyecto4, proyecto5];

  const services = {
    title: "Detección de Humo: ",
    subtitle: "Seguridad y Tranquilidad en tus espacios",
    description:
      "Nuestros detectores de humo emiten alarmas visuales o sonoras al detectar humo, garantizando una pronta evacuación. Esencial en hogares, locales comerciales, restaurantes, centros comerciales, bodegas y establecimientos educativos o de salud. Ofrecemos detección temprana, alertas inmediatas, protección de vidas y propiedades, cumplimiento normativo, y la tranquilidad y confianza que necesitas para estar protegido.",
  };
  return (
    <div>
      <Header />
      <section className="services">
        <h2 className="display-1 text-red text-center mb-5 text-red">
          Detección de <span className="fw-bold">humo</span>
        </h2>
        <Row className="m-0 p-0 g-0">

          <Col md={8}>
            <Gallery galleryImages={galleryImages} />
          </Col>
          <Col md={4}>
            <CardComponent
              title={services.title}
              subtitle={services.subtitle}
              description={services.description}
            />
          </Col>
        </Row>
        <Services service={HumoImage} />
      </section>
      <Footer />
    </div>
  );
}

export default Humo;
