import React from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import error from "../assets/404.png";
import "./styles/404.css"
const Pagina404 = () => {
  return (
    <section style={{ backgroundColor: "#B40013" }}>
      <Container className="d-flex justify-content-center align-items-center vh-100 bg-transparent">
        <Card className="text-center bg-transparent">
          <Card.Img
            src={error}
            alt="Página no encontrada"
            className="img-fluid mx-auto"
          />
          <Card.Body className="d-flex flex-column align-items-center">
            <Link to="/" className="text-white fs-3 text-decoration-none hover-404">
              Volver
            </Link>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
};

export default Pagina404;
