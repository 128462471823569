import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col, Spinner } from "react-bootstrap";
import "./styles/Blog.css";
import Header from "./Header";
import Footer from "./Footer";

const fetchDataFromAPI = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  const response = await fetch(
    "https://posting.ccgltda.com/wp-json/wp/v2/posts/?page=1&status=publish&categories=14",
    requestOptions
  );
  return await response.json();
};

const Blog = () => {
  const [dataBlog, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const updateDataFromAPI = () => {
    fetchDataFromAPI()
      .then((responseData) => {
        // Almacena los datos en la caché local
        localStorage.setItem("cachedData", JSON.stringify(responseData));
        setData(responseData);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    // Intenta cargar datos desde la caché local al inicio
    const cachedData = localStorage.getItem("cachedData");
    if (cachedData) {
      setData(JSON.parse(cachedData));
      setIsLoading(false);
    } else {
      fetchDataFromAPI()
        .then((responseData) => {
          // Almacena los datos en la caché local
          localStorage.setItem("cachedData", JSON.stringify(responseData));
          setData(responseData);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setError(error);
        });
    }

    // Programa la actualización automática cada 3 horas
    const updateInterval = setInterval(() => {
      updateDataFromAPI();
    }, 3 * 60 * 60 * 1000); // 3 horas en milisegundos

    return () => {
      // Limpia el intervalo cuando el componente se desmonta
      clearInterval(updateInterval);
    };
  }, []);

  const parseExcerpt = (excerpt) => {
    const sanitizedExcerpt = excerpt.replace(/<p>/g, "").replace(/<\/p>/g, "");
    return sanitizedExcerpt;
  };

  return (
    <div>
      <Header />
      <Container className="my-5">
        <Row>
          {isLoading ? (
            <div className="text-center">
              <Spinner animation="border" variant="danger" />
              <p>Cargando...</p>
            </div>
          ) : error ? (
            <Col>
              <p>No hay blogs disponibles</p>
            </Col>
          ) : (
            dataBlog.map((post) => (
              <Col
                key={post.id}
                md={4}
                sm={6}
                xs={12}
                className="d-flex align-items-center"
              >
                <Card className="card mb-0 w-100">
                  <Card.Img
                    variant="top"
                    src={post.jetpack_featured_media_url}
                  />

                  <div>
                    <div>
                      <p className="fs-2 mt-2 text-gray fw-bold">
                        {post.title.rendered}
                      </p>
                      <div className="text-gray">
                        {parseExcerpt(post.excerpt.rendered)}
                      </div>
                      <Link
                        to={`/Blog/${post.slug}`}
                        className="text-decoration-none text-blue fw-bold"
                      >
                        Continuar Leyendo
                      </Link>
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Blog;
