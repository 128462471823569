import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FormContact from "./Form";
import Header from "./Header";
import Footer from "./Footer";
import video from "../assets/Contacto/background.mp4";
import "./styles/Contact.css";
const Contact = () => {
  return (
    <div>
      <Header />
      <section className="contact-container">
        <video
          src={video}
          autoPlay={true}
          loop={true}
          className="contact-video"
        ></video>

        <Container>
          <Container className="py-2 lds-ellipsis d-flex align-items-center justify-content-center">
            <p className="text-center fs-4 text-white  py-2 ">
              Deja tu <span className="fw-bold">Seguridad</span> en nuestras
              manos
            </p>
          </Container>
          <FormContact />

          <Container className="text-white">
            <Row className="align-items-center mx-2 mt-3 justify-content-center mt-5">
              <Col md={4} className="">
                <p className="fw-bold m-0">
                  Horario de atención(Lunes a Viernes):
                </p>
                <ul>
                  <li>Día:Lunes a Viernes</li>
                  <li>Horarios:8:00am a 5:00pm</li>
                </ul>
              </Col>
              <Col md={4} className="">
                <p className="fw-bold m-0">Horario de atención(Sábado):</p>
                <ul>
                  <li>Día:Sábado</li>
                  <li>Horarios:8:00am a 1:00pm</li>
                </ul>
              </Col>
              <Col md={4} className="">
                <p className="fw-bold m-0">Servicios de instalación</p>
                <ul>
                  <li>Día: 7 día de la semana </li>
                  <li>Horarios:Disponible las 24 horas</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
