import React, { useEffect, useState } from "react";
import { Col, Container, Card, Row, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import Aside from "./Aside";
import Header from "./Header";
import Footer from "./Footer";

const ContentBlog = () => {
  const location = useLocation();
  const [contentHTML, setcontentHTML] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const pathname = location.pathname;
  const match = pathname.match(/\/Blog\/([^/]+)/);
  const slug = match ? match[1] : null;
  const fetchDataFromAPI = async (slug) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    const response = await fetch(
      `https://posting.ccgltda.com/wp-json/wp/v2/posts?slug=${slug}`,
      requestOptions
    );
    return await response.json();
  };

  useEffect(() => {
    const cachedData = localStorage.getItem("cachedData");
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      const content = parsedData[0].content.rendered;
      setcontentHTML(content);
      setIsLoading(false);
    } else {
      if (slug) {
        fetchDataFromAPI(slug)
          .then((responseData) => {
            localStorage.setItem("cachedData", JSON.stringify(responseData));
            setcontentHTML(responseData[0].content.rendered);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            setError(error);
          });
      } else {
        setIsLoading(false);
        setError("No hay slug disponible");
      }
    }
    // ...
  }, [slug]);
  
  const addImageClass = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;
    const images = tempDiv.querySelectorAll("img");
    images.forEach((image) => {
      image.classList.add("img-fluid");
    });
    return tempDiv.innerHTML;
  };

  if (isLoading) {
    return (
      <div>
        <Header />
        <Container className="mb-5">
          <div className="text-center">
            <Spinner animation="border" variant="danger" />
            <p>Cargando...</p>
          </div>
        </Container>
        <Footer />
      </div>
    );
  } else if (error || !slug) {
    return (
      <div>
        <Header />
        <Container className="mb-5">
          <Col>
            <p>No hay blogs disponibles</p>
          </Col>
        </Container>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <Container className="mb-5">
          <Row>
            <Col md={9} className="d-flex align-items-center">
              {" "}
              <Card className="w-100">
                <div
                  className="lead d-flex flex-column justify-content-center align-items-center"
                  dangerouslySetInnerHTML={{
                    __html: addImageClass(contentHTML),
                  }}
                ></div>
              </Card>
            </Col>
            <Col md={3}>
              <Aside />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    );
  }
};

export default ContentBlog;
