import React from 'react'
import "./styles/Whatsapp.css"
import whastapp from "../assets/whatsapp.png"
const Whatsapp = () => {
  return (
  
    <a href="https://api.whatsapp.com/send?phone=573228279292&text=¡Hola+Red+Blaze%21+🔥%0AVi+sus+servicios+en+la+página+web+y+me+gustaría+recibir+una+asesoría+personalizada"
        className="whatsapp-button" target="_blank" rel="noreferrer">
        <img src={whastapp} alt="whatsapp" className="whatsapp-button  img-fluid" />
    </a>
  
  )
}

export default Whatsapp
