import React, { useState } from "react";
import Servicio from "../assets/Servicios/Servicio.png";
import { Card } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import "./styles/CardComponent.css"; // Create a CSS file for animations

const CardComponent = ({ title, subtitle, description }) => {
  const [showTitle, setShowTitle] = useState(true);
  const [clicked, setClicked] = useState(false);

  const toggleTextVisibility = () => {
    setShowTitle(!showTitle);
    setClicked(!clicked);
  };

  const handleMouseLeave = () => {
    setShowTitle(true);
    setClicked(false);
  };

  return (
    <Card
      className={`text-white w-100 p-0 m-0 card-services ${
        clicked ? "bg-clicked" : ""
      }`}
      onMouseLeave={handleMouseLeave}
    >
      <Card.Img
        src={Servicio}
        alt="Servicios que ofrece Red Blaze"
        className="w-100 img-fluid h-100"
      />

      {/* Animar el título */}
      <CSSTransition
        in={showTitle}
        timeout={4000}
        classNames="card-content"
        unmountOnExit
      >
        <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center ">
          <p className="text-center lead text-center">
            <span className="fw-bold">{title}</span> {subtitle}
          </p>
          <i
            className={`fas fa-chevron-down fa-xl ${
              clicked ? "text-white" : ""
            }`}
            style={{ cursor: "pointer" }}
            onClick={toggleTextVisibility}
          ></i>
        </Card.ImgOverlay>
      </CSSTransition>

      {/* Animar la descripción */}
      <CSSTransition
        in={!showTitle}
        timeout={300}
        classNames="card-content"
        unmountOnExit
      >
        <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center w-100 description">
          <p>{description}</p>
        </Card.ImgOverlay>
      </CSSTransition>
    </Card>
  );
};

export default CardComponent;
