import React from "react";
const Services = (image) => {
  return (
    <section className="m-0 p-0">
      <img src={image.service} alt ={image.service} className="img-fluid w-100"   style={{
                minHeight: "200px",
              }} />
    </section>
  );
};

export default Services;
